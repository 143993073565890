.root {
    border: 1px solid #e5e5e5;
    margin: 10px 10px 10px 10px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 18px;
    transition: 600ms ease-in-out;
}

.list {
    overflow-y: auto;
    max-height: 300px;
}

.selectedRoot {
    border: 1px solid #93c5fd;
    transition: 600ms ease-in-out;
}

.emptyRoot {
    display: none;
}

.title {
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.icon {
    color: #a3a3a3;
    cursor: pointer;
    transition: 600ms ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selectedIcon {
    color: #525252;
    transition: 600ms ease-in-out;
}

.icon:hover {
    color: #525252;
    transition: 600ms ease-in-out;
}

.icon:active {
    color: #262626;
    transition: 600ms ease-in-out;
}