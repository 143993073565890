.root {
    border-right: 1px solid;
    border-color: #525252;
    height: 100vh;
    text-align: center;
    box-sizing: border-box;
}

.menuItem {
    display: flex;
    width: 140px;
    height: 60px;
    cursor: pointer;
    padding: 2px;
    border-radius: 5px;
    color: #525252 ;    
    padding: 5px 10px;
    font-size: 16px;
    align-items: center;
    margin: 5px;
    transition: 600ms ease-in-out;

}

.menuItem:hover {
    background-color: #dbeafe; 
    transition: 600ms ease-in-out;
}

.activeMenuItem {
    background-color: #bfdbfe;
}

.text {
    color: #262626;
}

.icon {
    margin-right: 10px;
}