.buttonBox {
    display: flex;
    margin-right: 50px;
    margin-top: 20px;
    justify-content: flex-end;
    width: 360px;
    margin-left: auto;
    position: relative;
}

.button {
    color: #525252;
    background-color: #e5e5e5;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    cursor: pointer;
    transition: 600ms;
    margin-left: 20px;
}

.button:hover {
    background-color: #a3a3a3;
    color: #404040;
    transition: 600ms;
}

.button:active {
    background-color: #525252;
    color: #e5e5e5;
    transition: 600ms;
}

.popUp {
    top: -120px;
    left: 380px ;
    position: absolute;
    width: 50px;
    height: 170px;
    background-color: transparent;
    border-radius: 25px;
    transition: 600ms ease-in-out;
    cursor: default;
    opacity: 100;
    box-sizing: border-box;
    display: block;
}

.popUpClose {
    display: none;
    transition: 600ms ease-in-out;
}

.sideBarButton {
    color: #525252;
    background-color: #e5e5e5;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    cursor: pointer;
    transition: 600ms ease-in-out;
    margin-top: 20px;
}

.sideBarButton:first-child {
    margin-top: 0px;
}

.sideBarButton:hover {
    background-color: #a3a3a3;
    color: #404040;
    transition: 600ms ease-in-out;
}

.sideBarButton:active {
    background-color: #525252;
    color: #e5e5e5;
    transition: 600ms ease-in-out;
}