.icon {
    margin-right: 10px;
    margin-left: 10px;
}

.caseItem {
    display: flex;
    margin: 10px;
    align-items: center;
    height: 80px;
    box-sizing: border-box;
    padding: 10px 0px 10px 10px;
    background-color: #e5e5e5;
    border-radius: 15px;
    cursor: pointer;
    text-align: left;
    color: #525252;
    font-size: 18px;
    transition: 400ms ease-in-out;

}

.caseItemActive {
    background-color: #bfdbfe;
    transition: 400ms ease-in-out;

} 
.client {
    color: #737373;
    font-size: 14px;
}


.tagMark {
    background-color: #6366f1;
    height: 80px;
    width: 15px;
    margin-left: auto;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.tagMarkDolgi {
    background-color: #22c55e;
}

.tagMarkDelicti {
    background-color: #f97316;
}

.tagMarkBancrot {
    background-color: #d946ef;
}

.tagMarkRealEstate {
    background-color: #84cc16;
}

.tagMarkGosSpori {
    background-color: #06b6d4;
}

.tagMarkKorporati {
    background-color: #ef4444;
}

.tagMarkNalogi {
    background-color: #eab308;
}

.tagMarkIntellect {
    background-color: #a855f7;
}