.root {
    width: 25%;
    height: 100%;
    box-sizing: border-box;
    border-right: 1px solid #525252;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 50px;
    border-bottom: 1px solid #525252;
    font-size: 16px;
    font-weight: 600;
}