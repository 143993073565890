.root {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    text-align: left;
}

.content {
    height: 95%;
    overflow-y: auto;
    width: 100%;
    padding: 10px 20px;
}

.button {
    margin-top: 20px;
    margin-left: auto;
}