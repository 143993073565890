.root {
    height: 120px;
    box-sizing: border-box;
    padding: 20px;
    background-color: #e5e5e5;
    border-radius: 20px;
    cursor: pointer;
    margin-top: 20px;
}

.info {
    width: 120px;
    display: flex;
    color: #525252;
}
.infoFooter {
    width: 250px;
    display: flex;
    color: #525252;
}

.billInfo {
    margin-left: 10px;
}
.billHeader {
    display: flex;
    align-items: center;
}
.billFooter {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.billBody {
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.tagMark {
    color: #6366f1;
    margin-right: 20px;
}

.tagMarkDolgi {
    color: #22c55e;
}

.tagMarkDelicti {
    color: #f97316;
}

.tagMarkBancrot {
    color: #d946ef;
}

.tagMarkRealEstate {
    color: #84cc16;
}

.tagMarkGosSpori {
    color: #06b6d4;
}

.tagMarkKorporati {
    color: #ef4444;
}

.tagMarkNalogi {
    color: #eab308;
}

.tagMarkIntellect {
    color: #f43f5e;
}