.root {
    background-color: #e5e5e5;
    height: 80px;
    margin: 10px;
    padding: 2px 10px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    color: #525252;
    cursor: pointer;
    transition: 400ms ease-in-out;

}

.activeRoot {
    background-color: #bfdbfe;
    transition: 400ms ease-in-out;

}

.avatar {
    width: 50px;
    height: 50px;
    background-color: #525252;
    border-radius: 100%;
    margin-right: 10px;
}

.user {
    font-size: 18px;
    text-align: left;
    
}

.content {
    width: 82%;
    display: flex;
    justify-content: space-between;
    align-items: center;    
}

.text {
    width: 100%;
}
.icon{
    cursor: pointer;
}
.case {
    text-align: right;
}
.specsSud {
    margin-left: 10px;
    margin-top: 5px;
    background-color: #16a34a;
    width: fit-content;
    padding: 5px;
    font-size: 14px;
    opacity: 90%;
    color: #e5e5e5;
    border-radius: 8px;
    cursor: pointer;
}

.specsAbonent {
    width: fit-content;
    padding: 5px;
    font-size: 14px;
    opacity: 90%;
    color: #e5e5e5;
    border-radius: 8px;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 5px;
    background-color: #0891b2;
}

.specsIt {
    width: fit-content;
    padding: 5px;
    font-size: 14px;
    opacity: 90%;
    color: #e5e5e5;
    border-radius: 8px;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 5px;
    background-color: #dc2626;
}

.specsCeo {
    width: fit-content;
    padding: 5px;
    font-size: 14px;
    opacity: 90%;
    color: #e5e5e5;
    border-radius: 8px;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 5px;
    background-color: #9333ea;
}

.specsOcenka {
    width: fit-content;
    padding: 5px;
    font-size: 14px;
    opacity: 90%;
    color: #e5e5e5;
    border-radius: 8px;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 5px;
    background-color: #ea580c;
}

.specsKadastr {
    width: fit-content;
    padding: 5px;
    font-size: 14px;
    opacity: 90%;
    color: #e5e5e5;
    border-radius: 8px;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 5px;
    background-color: #ca8a04;
}