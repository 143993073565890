.root {
    text-align: center;
    font-size: 16px;
    box-sizing: border-box;
    border: 1px #e5e5e5 solid;
    border-radius: 10px;
    padding: 10px;
    min-width: 180px;
    max-width: 180px;
    height: 220px;
    margin: 0px 5px;
    cursor: pointer;
}

.name {
    font-weight: 600;
    height: 60px;
    box-sizing: border-box;
    padding: 0px 15px;
    margin-bottom: 15px;
}
.job {
    font-style: italic;
    margin-bottom: 15px;
}
.contacts {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    cursor: pointer;
}

.icon {
    margin-right: 5px;
}

.buttonBox {
    opacity: 0%;
    transition: 600ms ease-in-out;
    display: flex;
    justify-content: space-between;
}

.buttonBoxShown {
    opacity: 100%;
    transition: 600ms ease-in-out;

}

.button {
    width: 30px;
    height: 30px;
    margin: 0;
    background-color: #e5e5e5;
    border: 0px;
    font-size: 16px;
    color: #525252;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 600ms ease-in-out;
}

.button:hover {
    background-color: #a3a3a3;
    transition: 600ms ease-in-out;
    
}