.infoBlock {
    display: flex;
    transition: 600ms ease-in-out;
}

.infoBlockHide {
    display: none;
    transition: 600ms ease-in-out;

}

.infoBlockSplit {
    width: 50%;
    padding: 10px 20px;
    box-sizing: border-box;
}


.editButton {
    color: #525252;
    background-color: #e5e5e5;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    cursor: pointer;
    transition: 600ms;
}

.editButton:hover {
    background-color: #a3a3a3;
    color: #404040;
    transition: 600ms;
}

.editButton:active {
    background-color: #525252;
    color: #e5e5e5;
    transition: 600ms;
}

.closeIcon {
    margin-left: auto;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    padding: 0px;
    margin-bottom: 10px;

    background-color: #e5e5e5;
    border: 0px;
    font-size: 16px;
    color: #525252;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 600ms ease-in-out;
}

.closeIcon:hover {
    background-color: #a3a3a3;
    transition: 600ms ease-in-out;    
}

.annotationTitle {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

.annotationText {
    overflow-y: auto;
    width: 100%;
    height: 250px;
}

.myArea {
    width: 100%;
    height: 200px;
    resize: none;
    outline: none;
    border-radius: 10px;
    border: 1px solid #525252;
    padding: 10px;
}

.myBlock {
    display: block;
}

.myBlockHide {
    display: none;
}

.button {
    margin-top: 20px;
    margin-left: auto;
    background-color: #e5e5e5;
    border: 0px;
    font-size: 16px;
    color: #525252;
    cursor: pointer;
    border-radius: 10px;
    height: 40px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 600ms ease-in-out;
}

.button:hover {
    background-color: #a3a3a3;
    transition: 600ms ease-in-out;    

}