.icon {
    margin-right: 10px;
    margin-left: 10px;
}

.clientItem {
    display: flex;
    margin: 10px 10px;
    align-items: center;
    height: 80px;
    box-sizing: border-box;
    padding: 10px 15px ;
    background-color: #e5e5e5;
    border-radius: 15px;
    cursor: pointer;
    text-align: left;
    color: #525252;
    font-size: 18px;
    transition: 400ms ease-in-out;

}

.clientItemActive {
    background-color: #bfdbfe;
    transition: 400ms ease-in-out;
}

.client {
    color: #737373;
    font-size: 14px;
}

.title {
    margin-left: 10px;
}