.root {
    display: flex;
    width: 100%;
    height: calc(100vh - 60px);
    box-sizing: border-box;
    padding: 10px;
}

.navigationPanel {
    width: 30%;
    margin-right: 5px;
}

.list {
    overflow-y: auto;
    width: 100%;
    height: calc(100vh - 170px);
}

.description {
    width: 70%;
}

.archiveAndDeletedClient {
    display: flex;
    box-sizing: border-box;
}

.archDel {
    display: flex;
    margin: 10px 10px;
    align-items: center;
    justify-content: center;
    height: 80px;
    box-sizing: border-box;
    padding: 10px 15px;
    border-radius: 15px;
    cursor: pointer;
    color: #a3a3a3;
    border: 1px #a3a3a3 solid;
    transition: 600ms ease-in-out;
    width: 50%;
}

.archDel:hover {
    border: 1px #525252 solid;
    color: #525252;
    transition: 600ms ease-in-out;
}

.archDel:active {
    border: 1px #404040 solid;
    color: #404040;
    transition: 600ms ease-in-out;
}

.popUp {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
}

.popUpClose {
    display: none;
}

.archDelScreen {
    width: 30%;
    height: 60%;
    background-color: white;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px;
}

.icon {
    margin-left: auto;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    padding: 0px;
}

.title {
    font-size: 18px;
    margin-left: 10px;
}

.titleVsButton {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    align-items: center;
    margin: 20px 0px;
}

.iconRotate {
    color: #525252;
    cursor: pointer;
    transition: 200ms ease-in-out;
}

.iconRotateTrue {
    color: #525252;
    cursor: pointer;
    transition: 200ms ease-in-out;
    transform: rotate(180deg);
}

.clientRoot {
    display: none;
}

.clientRootOpen {
    display: block;
}