.root {
    background-color: #e5e5e5;
    min-height: 60px;
    width: 400px;
    margin: 5px 20px;
    box-sizing: border-box;
    padding: 5px 10px;
    border-radius: 15px;
    text-align: left;
}

.youroot {
    background-color: #bfdbfe;
    min-height: 60px;
    margin-left: auto;
    width: 400px;
    box-sizing: border-box;
    padding: 5px 10px;
    border-radius: 15px;
    text-align: right;
}

.user {
    min-height: 60px;
    width: 400px;
    box-sizing: border-box;
    border-radius: 15px;
    text-align: left;
}

.youUser {
    min-height: 60px;
    margin-left: auto;
    width: 400px;
    box-sizing: border-box;
    border-radius: 15px;
    text-align: right;
}

.userName {
    margin-left: 25px;
    font-weight: 600;
}