.root {
    display: flex;
    width: 100%;
    height: calc(100vh - 60px);
    box-sizing: border-box;
    padding: 10px;
}

.users {
    width: 30%;
    overflow-y: auto;
    margin-right: 5px;
    box-sizing: border-box;
}

.screen {
    width: 70%;
    box-sizing: border-box;
    padding-right: 25px;
}

.myList {
    margin: 0px;
    padding: 0px;
}