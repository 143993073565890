.root {
    display: flex;
    margin: 10px 10px;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 15px;
    border-radius: 15px;
    cursor: pointer;
    color: #a3a3a3;
    border: 1px #a3a3a3 solid;
    transition: 600ms ease-in-out;
}

.root:hover {
    border: 1px #525252 solid;
    color: #525252;
    transition: 600ms ease-in-out;
}

.root:active {
    border: 1px #525252 solid;
    color: #404040;
    transition: 600ms ease-in-out;
}

.popUp {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.popUpClose {
    display: none;
}

.deleteCaseMenu {
    width: 40%;
    height: 20%;
    background-color: white;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px;
    text-align: center;
}

.closeIcon {
    margin-left: auto;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    padding: 0px;
    margin-bottom: 10px;
    background-color: #e5e5e5;
    border: 0px;
    font-size: 16px;
    color: #525252;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 600ms ease-in-out;
    margin-left: auto;
}

.closeIcon:hover {
    background-color: #a3a3a3;
    transition: 600ms ease-in-out;
    
}

.input {
    border: 1px solid #a3a3a3;
    width: 100%;
    height: 40px;
    border-radius: 10px;
    padding-left: 10px;
    color: #525252 ;
    outline: none;
    box-sizing: border-box;
}

.inputBox {
    display: flex;
    margin: 40px 40px 10px 40px;
}

.button {
    background-color: #e5e5e5;
    border: 0px;
    font-size: 16px;
    color: #525252;
    cursor: pointer;
    border-radius: 10px;
    height: 40px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 600ms ease-in-out;
    margin-left: 10px;

}

.button:hover {
    background-color: #a3a3a3;
    transition: 600ms ease-in-out;
    
}