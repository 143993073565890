.icon {
    margin-right: 10px;
    margin-left: 10px;
}

.caseItem {
    display: flex;
    margin: 10px;
    align-items: center;
    min-height: 80px;
    box-sizing: border-box;
    padding: 10px 0px 10px 10px;
    background-color: #e5e5e5;
    border-radius: 10px;
    cursor: pointer;
    text-align: left;
    color: #525252;
    font-size: 18px;
    transition: 600ms ease-in-out;
    position: relative;
}

.caseBody {
    width: 80%;
}

.title {
    min-width: 80%;
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.caseItemActive {
    background-color: #dbeafe;
    transition: 600ms ease-in-out;
    box-sizing: border-box;

} 
.client {
    color: #737373;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.tagMark {
    background-color: #6366f1;
    height: 100%;
    width: 10px;
    margin-left: auto;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: 600ms ease-in-out;
    position: absolute;
    left: calc(100% - 10px);
    z-index: 5;
}

.tagMarkActive {
    width: 50px;
    transition: 600ms ease-in-out;
    left: calc(100% - 50px);
}

.tagMarkDolgi {
    background-color: #22c55e;
}

.tagMarkDelicti {
    background-color: #f97316;
}

.tagMarkBancrot {
    background-color: #d946ef;
}

.tagMarkRealEstate {
    background-color: #84cc16;
}

.tagMarkGosSpori {
    background-color: #06b6d4;
}

.tagMarkKorporati {
    background-color: #ef4444;
}

.tagMarkNalogi {
    background-color: #eab308;
}

.tagMarkIntellect {
    background-color: #f43f5e;
}

// Бортики

.caseBorderOther {
    border: 1px solid #6366f1;
    transition: 600ms ease-in-out;

}

.caseBorderDolgi {
    border: 1px solid #22c55e;
    transition: 600ms ease-in-out;

}

.caseBorderDelicti {
    border: 1px solid #f97316;
    transition: 600ms ease-in-out;

}

.caseBorderBancrot {
    border: 1px solid #d946ef;
    transition: 600ms ease-in-out;

}

.caseBorderkRealEstate {
    border: 1px solid #84cc16;
    transition: 600ms ease-in-out;

}

.caseBorderGosSpori {
    border: 1px solid #06b6d4;
    transition: 600ms ease-in-out;

}

.caseBorderKorporati {
    border: 1px solid #ef4444;
    transition: 600ms ease-in-out;

}

.caseBorderNalogi {
    border: 1px solid #eab308;
    transition: 600ms ease-in-out;

}

.caseBorderIntellect {
    border: 1px solid #f43f5e;
    transition: 600ms ease-in-out;

}