.root {
    height: calc(100vh - 60px);
    box-sizing: border-box;
    overflow: hidden;
}

.content {
    display: flex;
    box-sizing: border-box;
}

.firstContent {
    padding: 20px;
    box-sizing: border-box;
    width: 33%;
    text-align: center;
    height: calc(100vh - 60px);
}

.secondContent {
    padding: 20px;
    box-sizing: border-box;
    width: 33%;
    text-align: center;
}

.todos {
    overflow-y: auto;
    height: calc(30% - 40px);
}

.billing {
    overflow-y: auto;
    height: calc(30% - 40px);
}

.title {
    height: 4%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}

.cases {
    box-sizing: border-box;
    overflow-y: auto;
    height: calc(60% - 40px);
}

.thirdContent {
    padding: 20px;
    height: calc(100vh - 60px);
    box-sizing: border-box;
    width: 33%;
}

.chats {
    height: calc(50% - 40px);
    overflow-y: auto;
    box-sizing: border-box;
}

.clients {
    height: calc(50% - 40px);
    box-sizing: border-box;
    overflow-y: auto;
}

.users {
    height: calc(50% - 40px);
    overflow-y: auto;
}

.disputes {
    overflow-y: auto;
    height: calc(40% - 40px);
}
