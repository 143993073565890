.icon {
    color: #a3a3a3;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 600ms ease-in-out;

}

.icon:hover {
    color: #525252;
    transition: 600ms ease-in-out;
}

.iconCompleted {
    color: #525252;
}

.todoItem {
    display: flex;
    margin: 10px 10px;
    align-items: center;
    height: 50px;
    box-sizing: border-box;
    padding: 10px;
    background-color: #e5e5e5;
    color: #525252;
    border-radius: 15px;
    text-align: left;
    justify-content: space-between;
    
}

.item {
    display: flex;
    box-sizing: border-box;

}

.title{
    display: flex;
    align-items: center;
    font-size: 16px;
    box-sizing: border-box;
}

.titleCompleted {
    text-decoration: line-through;
}

.titleMenu {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 18px;
    margin-bottom: 10px;
}
.infoTitle {
    font-size: 20px;
}

.chatIcon {
    color: #a3a3a3;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
    transition: 600ms ease-in-out;

}

.chatIcon:hover {
    color: #525252;
    transition: 600ms ease-in-out;
}

.chat {
    display: none;
    box-sizing: border-box;
}

.chatOpen {
    display: block;
    box-sizing: border-box;
}

.iconBox {
    display: flex;
}

.popUp {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.popUpClose {
    display: none;
}

.deleteCaseMenu {
    width: 40%;
    height: 25%;
    background-color: white;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px;
    text-align: center;
}

.infoMenuOpen {
    width: 40%;
    height: 25%;
    background-color: white;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px;
    text-align: center;
}

.closeIcon {
    margin-left: auto;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    padding: 0px;
    margin-bottom: 10px;
}

.deleteButtons {
    display: flex;
    margin-top: 40px;
    align-items: center;
    justify-content: center;
}

.closeIcon {
    margin-left: auto;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    padding: 0px;
    margin-bottom: 10px;
    background-color: #e5e5e5;
    border: 0px;
    font-size: 16px;
    color: #525252;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 600ms ease-in-out;
    margin-left: auto;
}

.closeIcon:hover {
    background-color: #a3a3a3;
    transition: 600ms ease-in-out;
    
}

.button {
    background-color: #e5e5e5;
    border: 0px;
    font-size: 16px;
    color: #525252;
    cursor: pointer;
    border-radius: 10px;
    height: 40px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 600ms ease-in-out;
    margin-left: 10px;

}

.button:hover {
    background-color: #a3a3a3;
    transition: 600ms ease-in-out;
}

.infoBlock {
    display: flex;
    width: 100%;
    margin-top: 10px;
}

.information {
    text-align: left;
    background-color: #ea580c;
    color: wheat;
    font-size: 16px;
    margin-left: auto;
    padding: 2px 5px;
    box-sizing: border-box;
    border-radius: 10px;
    height: 30px;
    width: 100px;
    text-align: center;
    align-items: center;
}

.informationCompleted {
    background-color: #059669;
}

.informationBlock {
    width: 50%;
    text-align: left;
    font-size: 16px;
}

.informationBlock:last-child {
    margin-left: auto;
    text-align: right;
}

.show {
    display: block;
}

.hide {
    display: none;
}