.root {
    width: 75%;
    height: 100%;
    box-sizing: border-box;
    border-right: 1px solid #525252;
}

.chat {
    box-sizing: border-box;
    padding: 50px;
    height: calc(100vh - 160px) ;
    overflow-y: auto;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    border-bottom: 1px solid #525252;
    font-size: 16px;
    font-weight: 600;
    box-sizing: border-box;
}   

.headerTitle {
    display: flex;
    align-items: center;
    height: 50px;
    font-size: 16px;
}

.headerIcons {
    display: flex;
    color: #525252;
    background-color: #e5e5e5;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    cursor: pointer;
    margin-right: 20px;
}

.headerIcons:hover {
    background-color: #a3a3a3;
    color: #404040;
    transition: all 600ms ease-in-out;
}
.headerIcons:active {
    background-color: #525252;
    color: #e5e5e5;
    transition: all 600ms ease-in-out;
}

.icon {
    margin: 0px 10px;
    color: #a3a3a3;
    box-sizing: border-box;
}

.input {
    height: 50px;
    box-sizing: border-box;
    border-top: 1px solid #525252;
    display: flex;
    align-items: center;
    width: 100%;
}