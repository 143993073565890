.root {
    width: 100%;
    max-height: 250px;
    border: 1px solid #e5e5e5;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 20px;
    border-radius: 20px;
    display: flex;
    
}

.emptyRoot {
    display: none;
}

.lawyerName {
    width: 25%;
}

.lawyerTasks {
    width: 75%;
    overflow-y: auto;
}

.specsSud {
    margin-top: 5px;
    background-color: #16a34a;
    width: fit-content;
    padding: 5px;
    font-size: 14px;
    opacity: 90%;
    color: #e5e5e5;
    border-radius: 8px;
    cursor: pointer;
}

.specsAbonent {
    width: fit-content;
    padding: 5px;
    font-size: 14px;
    opacity: 90%;
    color: #e5e5e5;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 5px;
    background-color: #0891b2;
}

.specsIt {
    width: fit-content;
    padding: 5px;
    font-size: 14px;
    opacity: 90%;
    color: #e5e5e5;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 5px;
    background-color: #dc2626;
}

.specsCeo {
    width: fit-content;
    padding: 5px;
    font-size: 14px;
    opacity: 90%;
    color: #e5e5e5;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 5px;
    background-color: #9333ea;
}

.specsOcenka {
    width: fit-content;
    padding: 5px;
    font-size: 14px;
    opacity: 90%;
    color: #e5e5e5;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 5px;
    background-color: #ea580c;
}

.specsKadastr {
    width: fit-content;
    padding: 5px;
    font-size: 14px;
    opacity: 90%;
    color: #e5e5e5;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 5px;
    background-color: #ca8a04;
}