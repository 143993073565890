.root {
    width: 100%;
    border: #e5e5e5 2px solid;
    box-sizing: border-box;
    border-radius: 25px;
    height: 100%;
}

.header {
    padding: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: #e5e5e5 2px solid;
}

.avatar {
    background-color: #525252;
    width: 200px;
    height: 200px;
    border-radius: 100%;
}

.name {
    margin-right: 50px;
    font-size: 24px;
    text-align: right;
}

.buttonBox {
    display: flex;
    margin-right: 50px;
    margin-top: 25px;
    justify-content: flex-end;
}

.button {
    color: #525252;
    background-color: #e5e5e5;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    cursor: pointer;
    margin-left: 20px;
}

.button:hover {
    background-color: #a3a3a3;
    color: #404040;
    transition: 600ms;
}

.button:active {
    background-color: #525252;
    color: #e5e5e5;
    transition: 600ms;
}

.content {
    display: flex;
    box-sizing: border-box;
    text-align: center;
    color: #404040;
    height: 60%;
}

.cases {
    width: 50%;
    border-right: #e5e5e5 2px solid;
    padding: 10px;
    overflow-y: auto;
}

.todos {
    width: 50%;
    padding: 10px;
    overflow-y: auto;
}

.title {
    font-size: 20px
}

.specsSud {
    margin-left: auto;
    margin-top: 5px;
    margin-right: 50px;
    background-color: #16a34a;
    width: fit-content;
    padding: 5px;
    font-size: 14px;
    opacity: 90%;
    color: #e5e5e5;
    border-radius: 8px;
    cursor: pointer;
}

.specsAbonent {
    width: fit-content;
    padding: 5px;
    font-size: 14px;
    opacity: 90%;
    color: #e5e5e5;
    border-radius: 8px;
    cursor: pointer;
    margin-left: auto;
    margin-top: 5px;
    margin-right: 50px;

    background-color: #0891b2;
}

.specsIt {
    width: fit-content;
    padding: 5px;
    font-size: 14px;
    opacity: 90%;
    color: #e5e5e5;
    border-radius: 8px;
    cursor: pointer;
    margin-left: auto;
    margin-top: 5px;
    margin-right: 50px;

    background-color: #dc2626;
}

.specsCeo {
    width: fit-content;
    padding: 5px;
    font-size: 14px;
    opacity: 90%;
    color: #e5e5e5;
    border-radius: 8px;
    cursor: pointer;
    margin-left: auto;
    margin-top: 5px;
    margin-right: 50px;
    
    background-color: #9333ea;
}

.specsOcenka {
    width: fit-content;
    padding: 5px;
    font-size: 14px;
    opacity: 90%;
    color: #e5e5e5;
    border-radius: 8px;
    cursor: pointer;
    margin-left: auto;
    margin-top: 5px;
    margin-right: 50px;
    background-color: #ea580c;
}

.specsKadastr {
    width: fit-content;
    padding: 5px;
    font-size: 14px;
    opacity: 90%;
    color: #e5e5e5;
    border-radius: 8px;
    cursor: pointer;
    margin-left: auto;
    margin-top: 5px;
    margin-right: 50px;
    background-color: #ca8a04;
}