.root {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 20px;
}

.input {
    border: 1px solid #a3a3a3;
    width: 100%;
    border-radius: 10px;
    padding-left: 10px;
    color: #525252 ;
    outline: none;
}

.button {
    background-color: #e5e5e5;
    border: 0px;
    font-size: 16px;
    color: #525252;
    cursor: pointer;
    border-radius: 10px;
    height: 40px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
}

.button:hover {
    background-color: #a3a3a3;
    transition: all;
    transition-duration: 600ms;
    
}   