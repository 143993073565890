.root {
    width: 100%;
    border: #e5e5e5 2px solid;
    box-sizing: border-box;
    border-radius: 25px;
    height: 100%;
}

.header {
    padding: 30px 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: #e5e5e5 2px solid;
    color: #525252;
    height: calc(25% - 60px);

}

.client {
    font-size: 24px;
    margin-right: 50px;
    text-align: right;
}

.title {
    font-size: 24px;
}

.caseLink {

}

.task {
    font-size: 20px;
}

.description {
    font-size: 16px;
}
.clientDescription {
    font-size: 16px;
    text-align: right;
}

.section {
    display: flex;
    height: calc(30% - 60px);
}

.users {
    width: 50%;
    height: 100%;
}
.userBox {
    width: 100%;
    overflow-y: auto;
    height: 100%;
}

.calendar {
    color: #525252;
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
}

.content {
    height: 38%;
    width: 100%;
    display: flex;
}

.todos {
    height: 95%;
    width: 50%;
    overflow-y: auto;
    text-align: center;
    font-size: 20px;
}


.inputBox {
    display: flex;
    box-sizing: border-box;
    width: 50%;
    align-items: center;
}

.inputBox:last-child {
    padding: 20px;
}
.input {
    height: 40px;
    width: 300px;
    box-sizing: border-box;
    border: 1px #525252 solid;
    border-radius: 10px;
    padding-left: 10px;
    outline: none;
}

.inputBlock {
    display: flex;
    width: 100%;
}

.select {
    outline: none;
    width: 250px;
    border-radius: 10px;
    cursor: pointer;
    
}

.billInput {
    width: 50px;
    height: 40px;
    border-radius: 10px;
    border: 1px solid #525252;
    box-sizing: border-box;
    outline: none;
    margin-left: 10px;
    padding-left: 10px;
}


.popUp {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.popUpClose {
    display: none;
}

.form {
    width: 30%;
    height: 20%;
    background-color: white;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px;
}

.infoMenu {
    width: 60%;
    height: 50%;
    background-color: white;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px;
}

.completedMenu {
    width: 30%;
    height: 45%;
    background-color: white;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px 40px;
}

.deleteCaseMenu {
    width: 50%;
    height: 25%;
    background-color: white;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px;
    text-align: center;
}

.changeCaseMenu {
    width: 40%;
    height: 90%;
    background-color: white;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px;
    text-align: center;
}



.closeIcon {
    margin-left: auto;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    padding: 0px;
    margin-bottom: 10px;
    background-color: #e5e5e5;
    border: 0px;
    font-size: 16px;
    color: #525252;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 600ms ease-in-out;
    margin-left: auto;
}

.popUpInput {
    display: flex;
    align-items: center;
    justify-content: center;
}

.addLawyer {
    text-align: center;
    box-sizing: border-box;
    border: 1px #e5e5e5 solid;
    border-radius: 15px;
    height: 80px;
    margin: 10px;
    justify-content:center;
    display: flex;
    align-items: center;
    color: #a3a3a3;
    margin-bottom: 10px;
    cursor: pointer;
    transition: 600ms ease-in-out;

}

.addLawyerHide {
    display: none;
}

.addLawyer:hover {
    border: 1px #525252 solid;
    color: #525252;
    transition: 600ms ease-in-out;
}

.addLawyer:active {
    border: 1px #525252 solid;
    color: #404040;
    transition: 600ms ease-in-out;

}

.deleteButtons {
    display: flex;
    margin-top: 40px;
    align-items: center;
    justify-content: center;
}

.annotationTitle {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

.completeCase {
    text-align: center;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.buttonComplete {
    display: block;
    background-color: #e5e5e5;
    border: 0px;
    font-size: 16px;
    color: #525252;
    cursor: pointer;
    border-radius: 10px;
    height: 40px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 600ms ease-in-out;
    margin-left: auto;
}

.buttonComplete:hover {
    background-color: #a3a3a3;
    transition: 600ms ease-in-out;
}

.myArea {
    width: 100%;
    height: 200px;
    resize: none;
    outline: none;
    border-radius: 10px;
    border: 1px solid #525252;
    padding: 10px;
}



.addButtonHide {
    display: none;
}

.addButton {
    display: block;
    background-color: #e5e5e5;
    border: 0px;
    font-size: 16px;
    color: #525252;
    cursor: pointer;
    border-radius: 10px;
    height: 40px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 600ms ease-in-out;
    margin-left: 10px;
}

.teg {
    font-size: 14px;
    margin: 10px 0px;
    color: white;
    padding: 5px;
}

.tagMark {
    background-color: #6366f1;
    font-size: 14px;
    margin: 10px 0px 10px auto;
    color: white;
    padding: 5px 7px;
    width: max-content;
    height: 20px;
    border-radius: 10px;
    
}

.tagMarkDolgi {
    background-color: #22c55e;
}

.tagMarkDelicti {
    background-color: #f97316;
}

.tagMarkBancrot {
    background-color: #d946ef;
}

.tagMarkRealEstate {
    background-color: #84cc16;
}

.tagMarkGosSpori {
    background-color: #06b6d4;
}

.tagMarkKorporati {
    background-color: #ef4444;
}

.tagMarkNalogi {
    background-color: #eab308;
}

.tagMarkIntellect {
    background-color: #f43f5e;
}


.button {
    background-color: #e5e5e5;
    border: 0px;
    font-size: 16px;
    color: #525252;
    cursor: pointer;
    border-radius: 10px;
    height: 40px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 600ms ease-in-out;
    margin-left: 10px;
}

.button:hover {
    background-color: #a3a3a3;
    transition: 600ms ease-in-out;
    
}

.linkToKad {
    color: #0284c7;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
    margin-left: 10px;
}
.titleforKad {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.taskMenu {
    width: 50%;
    height: 60%;
    background-color: white;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px;
}

.closeIcon:hover {
    background-color: #a3a3a3;
    transition: 600ms ease-in-out;    
}