.root {
    height: 60px;
    margin: 10px 10px;
    background-color: #e5e5e5;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.root:hover {
    background-color: #a3a3a3;
    transition: background-color 600ms ease-in-out;
}

.root:active {
    background-color: #737373;
    transition: background-color 300ms ease-in-out;
}

.avatar {
    height: 40px;
    width: 40px;
    background-color: #525252;
    border-radius: 100%;
    margin-right: 20px;
}