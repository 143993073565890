.root {
    width: 100%;
    border: #e5e5e5 2px solid;
    box-sizing: border-box;
    border-radius: 25px;
    height: 100%;
}

.header {
    padding: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: #e5e5e5 2px solid;
    color: #525252;
}

.client {
    font-size: 24px;
    margin-right: 50px;
    text-align: right;
}

.task {
    font-size: 20px;
}

.description {
    font-size: 16px;
}
.clientDescription {
    font-size: 16px;
    text-align: right;
}

.section {
    display: flex;
    height: calc(30% - 60px);
}

.users {
    width: 50%;
    height: 100%;
}
.userBox {
    width: 100%;
    overflow-y: auto;
    height: 100%;
}

.calendar {
    color: #525252;
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
}

.content {
    height: 40%;
    width: 100%;
    display: flex;
}

.todos {
    height: 100%;
    width: 50%;
    overflow-y: auto;
}


.inputBox {
    display: flex;
    box-sizing: border-box;
    padding: 20px;
    width: 50%;
    align-items: center;
}
.input {
    height: 40px;
    width: 300px;
    box-sizing: border-box;
    border: 1px #525252 solid;
    border-radius: 10px;
    padding-left: 10px;
    outline: none;
}

.inputBlock {
    display: flex;
    width: 100%;
}

.select {
    outline: none;
    width: 250px;
    border-radius: 10px;
    cursor: pointer;
    
}

.billInput {
    width: 50px;
    height: 40px;
    border-radius: 10px;
    border: 1px solid #525252;
    box-sizing: border-box;
    outline: none;
    margin-left: 10px;
    padding-left: 10px;
}


.popUp {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.popUpClose {
    display: none;
}

.form {
    width: 30%;
    height: 20%;
    background-color: white;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px;
}

.infoMenu {
    width: 60%;
    height: 40%;
    background-color: white;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px;
}

.completedMenu {
    width: 40%;
    height: 45%;
    background-color: white;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px 40px;
}

.deleteCaseMenu {
    width: 50%;
    height: 25%;
    background-color: white;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px;
    text-align: center;
}

.changeCaseMenu {
    width: 40%;
    height: 90%;
    background-color: white;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px;
    text-align: center;
}

.infoBlock {
    display: flex;
}

.infoBlockSplit {
    width: 50%;
}

.closeIcon {
    margin-left: auto;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    padding: 0px;
    margin-bottom: 10px;
}

.popUpInput {
    display: flex;
    align-items: center;
    justify-content: center;
}

.addLawyer {
    text-align: center;
    box-sizing: border-box;
    border: 1px #e5e5e5 solid;
    border-radius: 15px;
    height: 80px;
    margin: 10px;
    justify-content:center;
    display: flex;
    align-items: center;
    color: #a3a3a3;
    margin-bottom: 10px;
    cursor: pointer;
    transition: 600ms ease-in-out;

}

.addLawyer:hover {
    border: 1px #525252 solid;
    color: #525252;
    transition: 600ms ease-in-out;
}

.addLawyer:active {
    border: 1px #525252 solid;
    color: #404040;
    transition: 600ms ease-in-out;

}

.deleteButtons {
    display: flex;
    margin-top: 40px;
    align-items: center;
    justify-content: center;
}

.annotationTitle {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

.completeCase {
    text-align: center;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.myArea {
    width: 100%;
    height: 200px;
    resize: none;
    outline: none;
    border-radius: 10px;
    border: 1px solid #525252;
    padding: 10px;
}

.button {
    margin-top: 20px;
    margin-left: auto;
}


.buttonBox {
    display: flex;
    margin-right: 50px;
    margin-top: 25px;
    justify-content: flex-end;
    width: 320px;
    margin-left: auto;
    position: relative;
}

.button {
    color: #525252;
    background-color: #e5e5e5;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    cursor: pointer;
    transition: 600ms;
    margin-left: 20px;
}

.button:hover {
    background-color: #a3a3a3;
    color: #404040;
    transition: 600ms;
}

.button:active {
    background-color: #525252;
    color: #e5e5e5;
    transition: 600ms;
}

.title {
    display: flex;
}