.icon {
    color: #a3a3a3;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.iconCompleted {
    color: #525252;
}

.todoItem {
    display: flex;
    margin: 10px 10px;
    align-items: center;
    height: 50px;
    box-sizing: border-box;
    padding: 10px;
    background-color: #e5e5e5;
    color: #525252;
    border-radius: 15px;
    text-align: left;
    justify-content: space-between;
}

.item {
    display: flex;
    box-sizing: border-box;

}

.title{
    display: flex;
    align-items: center;
    font-size: 16px;
    box-sizing: border-box;

}

.miniTitle {
    margin-left: 15px;
}