.root {
    display: flex;
    margin-top: 10px;
}

.box {
    width: 50%;
    box-sizing: border-box;
    margin-top: 10px;
    font-size: 16px;
}

.timeBox {
    text-align: left;
    height: 45px;
    font-size: 14px;
    border-bottom: 1px solid #525252;
    text-align: start;
    box-sizing: border-box;
    margin: 2px 10px;
    display: flex;
    align-items: center;
}

.time {
    display: flex;
    align-items: center;
    width: 40px;
    height: 20px;
    margin-right: 10px;
}

.icon {
    margin-right: 10px;
    cursor: pointer;
}