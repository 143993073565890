.root {
    font-size: 14px;
}

.title {
    font-size: 16px;
    margin-top: 8px;
}

.input {
    border: 1px solid #a3a3a3;
    width: 100%;
    height: 40px;
    border-radius: 10px;
    padding-left: 10px;
    color: #525252 ;
    outline: none;
    box-sizing: border-box;
    margin-top: 5px;
}