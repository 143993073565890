.root {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: calc(100vh - 60px);

}

.leftList{
    display: block;
    width: 30%;
    margin-right: 5px;

}

.list {
    padding: 20px;
    text-align: center;
    box-sizing: border-box;
    font-size: 20px;
    overflow-y: auto;
    height: calc(100vh - 170px);
}

.screen {
    width: 70%;
}

.archiveAndDeletedClient {
    display: flex;
    box-sizing: border-box;
}

.archDel {
    display: flex;
    margin: 10px 10px;
    align-items: center;
    justify-content: center;
    height: 80px;
    box-sizing: border-box;
    padding: 10px 15px;
    border-radius: 15px;
    cursor: pointer;
    color: #a3a3a3;
    border: 1px #a3a3a3 solid;
    transition: 600ms ease-in-out;
    width: 50%;
}

.archDel:hover {
    border: 1px #525252 solid;
    color: #525252;
    transition: 600ms ease-in-out;
}

.archDel:active {
    border: 1px #404040 solid;
    color: #404040;
    transition: 600ms ease-in-out;
}