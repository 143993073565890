.root {
    height: calc(100% - 60px);
}
.fullRoot {
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    padding-right: 3px;
}

.emptyRoot {
    height: 100%;
    padding: auto;
    align-items: center;
    display: flex;
    justify-content: center;
}

.title {
    height: 200px;
    font-size: 20px;
    text-align: center;
}

.button {
    background-color: #e5e5e5;
    border: 0px;
    font-size: 16px;
    color: #525252;
    cursor: pointer;
    border-radius: 10px;
    height: 40px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 600ms ease-in-out;
    margin-right: 10px;
}

.button:hover {
    background-color: #a3a3a3;
    transition: 600ms ease-in-out;
    
}

.buttonBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.buttonActive {
    background-color: #525252;
    border: 0px;
    font-size: 16px;
    color: #e5e5e5;
    cursor: pointer;
    border-radius: 10px;
    height: 40px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 600ms ease-in-out;
    margin-right: 10px;
}

.closeIcon {
    margin-left: auto;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    padding: 0px;
    margin-bottom: 10px;
    background-color: #e5e5e5;
    border: 0px;
    font-size: 16px;
    color: #525252;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 600ms ease-in-out;
    margin-left: auto;
}

.closeIcon:hover {
    background-color: #a3a3a3;
    transition: 600ms ease-in-out;    
}