.root {
    width: 100%;
    text-align: left;
    box-sizing: border-box;
    padding: 0px 20px;
}

.rangeInfo {
    display: flex;
    align-items: center;
}

.title {
    width: 70%;
    display: flex;
    justify-content: space-between  ;
}
.range {
    width: 70%;
    margin-top: 10px;
    margin-right: 25px;
}