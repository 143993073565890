.root {
    width: 100%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}

.formRoot {
    width: 40%;
}

.input {
    width: 100%;
    height: 40px;
    outline: none;
    border: 1px solid #525252;
    border-radius: 10px;
    background-color: #f5f5f5;
    margin-bottom: 10px;
    box-sizing: border-box;
    padding: 10px;
    transition: 600ms ease-in-out;
}

.input:active {
    background-color: #e5e5e5;
    transition: 600ms ease-in-out;
}

.input:focus {
    background-color: #e5e5e5;
    transition: 600ms ease-in-out;
}

.buttonDiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button {
    background-color: #e5e5e5;
    border: 0px;
    font-size: 16px;
    color: #525252;
    cursor: pointer;
    border-radius: 10px;
    height: 40px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 600ms ease-in-out;
}

.button:hover {
    background-color: #a3a3a3;
    transition: 600ms ease-in-out;
    
}
