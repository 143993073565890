.root {
    width: 100%;
    border: #e5e5e5 2px solid;
    box-sizing: border-box;
    border-radius: 25px;
    height: 100%;
}

.header {
    padding: 30px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: #e5e5e5 2px solid;
    color: #525252;
}

.rootContent{
    padding-top: 10px;
    box-sizing: border-box;
    height: calc(32vh - 60px);
}

.content {
    display: flex;
    box-sizing: border-box;
    height: 100%;
}

.cases {
    width: 50%;
    height: 100%;
    font-size: 20px;
    text-align: center;
}

.caseList{
    overflow-y: auto;
    height: 100%;
}

.todos {
    width: 50%;
    height: 95%;
    font-size: 20px;
    overflow-y: auto;
}

.client {
    font-size: 24px;
    margin-right: 50px;
    text-align: end;
}

.phone {
    display: flex;
    align-items: center;
    justify-content: end;
}

.icon {
    margin-right: 10px;
}

.data {
    font-size: 20px;
}

.dataNumbers {
    font-size: 16px;
}

.calendar {
    color: #525252;
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
}

.information {
    display: flex;
    box-sizing: border-box;
    border-bottom: #e5e5e5 2px solid;
    height: calc(42% - 80px);

}

.contacts {
    width: 50%;
    align-items: center;
    text-align: center;
    font-size: 20px;
    box-sizing: border-box;
    padding: 5px;
    border-right: #e5e5e5 2px solid;
}

.contactsCard {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 20px 20px 20px ;
    margin-top: 5px;
}

.title {
    text-align: center;
}

.buttonBox {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 20px;
    margin-left: auto;
}

.button {
    color: #525252;
    background-color: #e5e5e5;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    cursor: pointer;
    margin-left: 20px;
    transition: 600ms ease-in-out;

}

.button:hover {
    background-color: #a3a3a3;
    color: #404040;
    transition: 600ms ease-in-out;
}

.button:active {
    background-color: #525252;
    color: #e5e5e5;
    transition: 600ms;
}

.popUp {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.popUpClose {
    display: none;
}

.form {
    width: 40%;
    height: 50%;
    background-color: white;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px;
}

.contactForm {
    width: 50%;
    height: 50%;
    background-color: white;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px;
}
.changeClientForm {
    width: 50%;
    height: 55%;
    background-color: white;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px;
}

.clientInfoMenu {
    width: 40%;
    height: 20%;
    background-color: white;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px;
    text-align: center;
}

.deleteButtons {
    display: flex;
    margin-top: 40px;
    align-items: center;
    justify-content: center;
}

.closeIcon {
    margin-left: auto;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    padding: 0px;
    margin-bottom: 10px;
    background-color: #e5e5e5;
    border: 0px;
    font-size: 16px;
    color: #525252;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 600ms ease-in-out;
    margin-left: auto;
}

.closeIcon:hover {
    background-color: #a3a3a3;
    transition: 600ms ease-in-out;
    
}

.contactsArray {
    display: flex;
    overflow-x: scroll;
    width: 380px;
    box-sizing: border-box;
    padding-bottom: 5px;
}

div ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 5px;
}

div ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}



div ::-webkit-scrollbar-thumb {
  background-color: darkgrey;
      border-radius: 10px;

}

.addContact{
    text-align: center;
    box-sizing: border-box;
    border: 1px #e5e5e5 solid;
    border-radius: 10px;
    padding: 10px;
    width: 150px;
    height: 220px;
    margin: 0px 5px;
    justify-content:center;
    display: flex;
    align-items: center;
    color: #a3a3a3;
    margin-bottom: 10px;
    margin-left: 30px;
    cursor: pointer;
    transition: 600ms ease-in-out;

}

.addContact:hover {
    border: 1px #525252 solid;
    color: #525252;
    transition: 600ms ease-in-out;
}

.addContact:active {
    border: 1px #525252 solid;
    color: #404040;
    transition: 600ms ease-in-out;
}

.addCase {
    display: flex;
    margin: 10px 10px;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 15px;
    border-radius: 15px;
    cursor: pointer;
    color: #a3a3a3;
    border: 1px #a3a3a3 solid;
    transition: 600ms ease-in-out;
}


.addCase:hover {
    border: 1px #525252 solid;
    color: #525252;
    transition: 600ms ease-in-out;
}

.addCase:active {
    border: 1px #404040 solid;
    color: #404040;
    transition: 600ms ease-in-out;
}

.inputBox {
    display: flex;
    box-sizing: border-box;
    width: 50%;
    align-items: center;
}

.buttons {
    display: flex;
}