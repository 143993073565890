.root {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
}

.form {
    width: 50%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}