.header {
    display: flex;
    height: 60px;
    color: #525252 ;    
    border-bottom: 1px solid #525252;
    box-sizing: border-box;
}

.sidebar {
    display: flex;
    width: 750px;
    margin-left: auto;
    margin-right: 30px;
    align-items: center;
    justify-content: right;
}

.buttonU {
    margin-right: 20px;
    cursor: pointer;
}
.button {
    background-color: #e5e5e5;
    border: 0px;
    font-size: 16px;
    color: #525252;
    cursor: pointer;
    border-radius: 10px;
    height: 40px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 600ms ease-in-out;
    margin-left: 10px;

}

.button:hover {
    background-color: #a3a3a3;
    transition: 600ms ease-in-out;
    
}

.date {
    font-size: 20px;
    margin-right: 20px;
}

.username {
    max-width: 200px;
    font-size: 20px;
    font-weight: 400;
}


.elem:hover {
    animation: 1.2s ease-in-out 0s normal none infinite running trambling-animation;
}
@keyframes trambling-animation {
    0%, 50%, 100% {
        transform: rotate(0deg);
    }
    10%, 30% {
        transform: rotate(-10deg);
    }
    20%, 40% {
        transform: rotate(10deg);
    }
}