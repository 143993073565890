.root {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.header {
    height: 95%;
    overflow-y: auto;
    display: flex;
    width: 100%;
}

.content {
    width: 100%;
    box-sizing: border-box;
    padding: 10px 20px;
}

.input {
    border: 1px solid #a3a3a3;
    width: 100%;
    height: 40px;
    border-radius: 10px;
    padding-left: 10px;
    font-size: 16px;
    color: #525252 ;
    outline: none;
    box-sizing: border-box;
    margin-top: 5px;
}

.title {
    font-size: 20px;
    margin-bottom: 10px
}

.button {
    margin-top: 20px;
    margin-left: auto;
}

.radio {
    display: flex;
    justify-content: space-between;
    width: 70%;
}

.input {
    border: 1px solid #a3a3a3;
    width: 100%;
    height: 40px;
    border-radius: 10px;
    padding-left: 10px;
    color: #525252 ;
    outline: none;
    box-sizing: border-box;
    margin-top: 5px;
}

.button {
    background-color: #e5e5e5;
    border: 0px;
    font-size: 16px;
    color: #525252;
    cursor: pointer;
    border-radius: 10px;
    height: 40px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 600ms ease-in-out;
    margin-left: auto;
}

.button:hover {
    background-color: #a3a3a3;
    transition: 600ms ease-in-out;
}